* {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}
html,
body,
#root {
    height: 100%;
    background-color: #f1f1f1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    padding: 16px 16px 1px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 5px #3333;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
}

.img-responsive {
    max-width: 100%;
}

.input-wrap {
    position: relative;
}

.currency {
    position: absolute;
    right: 16px;
    top: 8px;
    padding: 8px 16px;
    color: #CBD5E1;
}

input:focus-visible+.currency {
    color: #2B63E3;
}

.footer {
    width: 91%;
    max-width: 469px;
    position: fixed;
    bottom: 0;
    z-index: 100;
}

.rate-icon {
    flex-shrink: 1;
    height: inherit;
    max-height: 150px;
    flex: 0 1 auto;
  
}

@media only screen and (max-width: 360px) {
  
    #tip-buttons{
        flex-wrap: nowrap;
    }
    #rate-us{
        margin: 10px 0px 17px;
        padding: 19px 9px 19px 19px;

    }
  }
  @media only screen and (max-height: 550px) {
    #tip-icon {
      display: none;
    }
  }