#arrowAnim {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    width: 15px;
    height: 15px;
    border: 2px solid;
    border-color: #fffa transparent transparent #fffa;
    transform: rotate(-45deg);
}

.arrowSliding {
    position: absolute;
    -webkit-animation: slide 2s linear infinite;
    animation: slide 2s linear infinite;
}

.delay1 {
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
}

.delay2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.delay3 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

@-webkit-keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(15px);
    }

    20% {
        opacity: 1;
        transform: translateX(9px);
    }

    80% {
        opacity: 1;
        transform: translateX(-9px);
    }

    100% {
        opacity: 0;
        transform: translateX(-15px);
    }
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(15px);
    }

    20% {
        opacity: 1;
        transform: translateX(9px);
    }

    80% {
        opacity: 1;
        transform: translateX(-9px);
    }

    100% {
        opacity: 0;
        transform: translateX(-15px);
    }
}